import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Building, EnvelopeFill, TelephoneFill } from "react-bootstrap-icons"
import { Container } from "react-bootstrap"
import { Helmet } from "react-helmet"
import Navbar from "../components/Navbar"

const Kontakt = () => {
  return (
    <div className={"d-flex flex-column gap-5"}>
      <Helmet>
        <title>Kontakt | Inwestprojekt</title>
      </Helmet>
      <div className={"site-banner"}>
        <StaticImage
          src={"../assets/images/contact.jpg"}
          alt={"budynek"}
          placeholder={"blurred"}
          className={"site-banner"}
          style={{ position: "absolute" }}
        />
        <Navbar />
        <div className={"tlo cover"}>
          <h1 className={"text-banner"}>Kontakt</h1>
        </div>
      </div>
      <div>
        <Container className="pt-5 pb-5 d-flex flex-column flex-lg-row justify-content-center align-items-center gap-5">
          <div
            style={{ width: "350px", height: "200px" }}
            className="box shadow d-flex flex-column justify-content-center align-items-center gap-3"
          >
            <div>
              <Building color="black" size="24" />
            </div>
            <div className="fw-bold">Adres</div>
            <div className="d-flex flex-column gap-1 align-items-center">
              <div>ul. Kościuszki 41/47</div>
              <div>87-100 Toruń</div>
            </div>
          </div>
          <div
            style={{ width: "350px", height: "200px" }}
            className="shadow d-flex flex-column justify-content-center align-items-center gap-3"
          >
            <div>
              <TelephoneFill color="black" size="24" />
            </div>
            <div className="fw-bold">Telefon</div>
            <div>
              tel: <a href="tel:56-623-20-77">56 623 20 77</a>
            </div>
          </div>
          <div
            style={{ width: "350px", height: "200px" }}
            className="shadow d-flex flex-column justify-content-center align-items-center gap-3"
          >
            <div>
              <EnvelopeFill color="black" size="24" />
            </div>
            <div className="fw-bold">E-mail</div>
            <div>
              <a href="mailto:sekretariat@inwestprojekt-torun.com">
                sekretariat@inwestprojekt-torun.com
              </a>
            </div>
          </div>
        </Container>
      </div>
      <div>
        <iframe
          id="gmap_canvas"
          src="https://maps.google.com/maps?q=Inwestprojekt,%20Toru%C5%84&t=&z=13&ie=UTF8&iwloc=&output=embed"
          frameBorder="0"
          scrolling="no"
          marginHeight="0"
          marginWidth="0"
          className={"map"}
          title={"inwestprojekt"}
        />
      </div>
    </div>
  )
}

export default Kontakt
